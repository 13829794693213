import config from "../../../config";
import fetcher from "../../../utils/fetcher";

export function remove(
  shlCycleId: number,
  ids: number[],
  setReload: (b: boolean) => void
) {
  const url = `${config.apiUrl}/SHLCycle/RemoveEmployeesFromSHLCycle?shlCycleID=${shlCycleId}`;
  fetcher
    .post(url, {
      body: JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((_) => {
      setReload(true);
    });
}

import ExaminationCycle from "../../../../entities/ExaminationCycle";
import EditButton from "../../../EditButton";

export interface ExaminationActionsProps {
  setReload: (fetch: boolean) => {};
  setOpen: (fetch: boolean) => {};
  open: boolean;
  examination: ExaminationCycle;
  setExamination: (examination: ExaminationCycle) => void;
  edit: (event: ExaminationCycle) => {},
}

const TestActions = ({
  examination,
  setOpen,
  edit
}: ExaminationActionsProps) => {

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-right"
        style={{ cursor: "pointer" }}
      >
        <EditButton color="primary"
          onClick={() => {
            edit(examination);
            setOpen(true);
          }}
        />
      </div>
    </>
  );
};

export default TestActions;
import React, { useEffect, useState } from "react";
import fetcher from "../../utils/fetcher";
import config from "../../config";
import {
    GridToolbarExport,
    GridValueGetterParams,
} from "@material-ui/data-grid";

import Participant from "../../entities/Participant";
import { createStyles, Grid, Link, makeStyles } from "@material-ui/core";
import DataGrid from "../DataGrid"

const generateColumns = () => [
    {
        field: "displayName",
        headerName: "User Name",
        sortable: false,
        width: 170,
        valueGetter: (params: GridValueGetterParams) => {
            const { firstName, lastName } = params.row;
            return `${lastName}, ${firstName}`;
        },
    },
    {
        field: "#Tickets",
        headerName: "#Tickets",
        width: 160,
        valueGetter: (params: GridValueGetterParams) => {
            const { ticketsNumber } = params.row;
            return ticketsNumber;
        },
    },
    {
        field: "email",
        headerName: "E-mail",
        width: 220,
        valueGetter: (params: GridValueGetterParams) => params.row.mail,
        renderCell: (params: any) => {
            const email = params.row.mail;
            return <Link href={`mailto:${email}`}>{email}</Link>;
        },
    },
    {
        field: "phone",
        headerName: "Phone",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
            const { phone } = params.row
            return phone;
        },
    },
    {
        field: "department",
        headerName: "Department",
        width: 140,
        valueGetter: (params: GridValueGetterParams) => {
            const { department } = params.row
            return department;
        },
    },
    {
        field: "office",
        headerName: "Office",
        width: 120,
        valueGetter: (params: GridValueGetterParams) => {
            const { office } = params.row
            return office;
        },
    },

];

const getRowId = (row: any) => row.employeeID;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontSize: "12px",
            margin: "2px",
            border: "None"
        },
        toolbar: {
            display: "none",
            direction: "rtl"
        },
    })
);

export default function LotteryParticipantsTable(props: any) {
    const { reload, setReload, lotteryId } = props;
    const [participants, setParticipants] = useState<Participant[]>([]);
    const classes = useStyles();

    useEffect(() => {
        if (reload) {
            const url = `${config.apiUrl}/LiveLottery/GetEmpoyeesByLottery/${lotteryId}`;
            fetcher
                .get(url)
                .then((response) => response.json())
                .then((data) => setParticipants(data))
        }
    }, [reload, setReload, lotteryId]);

    return (
        <>
            <DataGrid
                namespace="LotteryParticipants"
                className={classes.root}
                rows={participants}
                columns={generateColumns()}
                getRowId={getRowId}
                components={{
                    Toolbar: () =>
                        <Grid container spacing={2}>
                            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                                    <GridToolbarExport />
                            </Grid>
                        </Grid>
                }}
            />
        </>
    );
}
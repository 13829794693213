import config from "../../config";
import fetcher from "../../utils/fetcher";

export function remove(
  eventId: number,
  ids: number[],
  setReload: (b: boolean) => void
) {
  const url = `${config.apiUrl}/HolidayGift/RemoveEmployeesToGiftEvent?eventID=${eventId}`;
  fetcher
    .post(url, {
      body: JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((_) => {
      setReload(true);
    });
}

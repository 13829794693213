export default function converStringDateToUtcDate(value: string) {
    if (!value) return null
    const data = new Date(value)
    data.setTime(data.getTime() + getOffsetTime(data))
    return data
}
function getOffsetTime(date: Date) {
    if (date.getTimezoneOffset() > 1)
        // gmt -
        return 0
    return date.getTimezoneOffset() * 60 * 1000 * -1
}
import { Typography, Grid } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import Event from "../../entities/Event";

const LockedEvent = ({ event }: { event: Event }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <LockIcon fontSize="large"/>
      </Grid>
      <Grid item>
        <Typography variant="h6">{event?.eventDesc} has been passed. </Typography>
        <Typography>That's impossible to do changes anymore.</Typography>
      </Grid>
    </Grid>
  );
};

export default LockedEvent;

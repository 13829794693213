import { makeStyles } from "@material-ui/core";
import {
  DataGrid as MuiDataGrid,
  GridFilterModel,
  GridSortModel,
} from "@material-ui/data-grid";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) =>
({
    root: {
      height:theme.spacing(94.25) ,
     width:theme.spacing(220),
       left:theme.spacing(0), 
      top:theme.spacing(7.5),
      borderRadius:theme.spacing(0.5),
      justify: "spaceBetween"
    },
})
);


export default function DataGrid(props: any) {
  const [sorting, setSorting] = useState<GridSortModel | undefined>();
  const [filter, setFilter] = useState<GridFilterModel | undefined>();
  const {namespace} = props;
  const classes=useStyles();

  useEffect(() => {
    const sort = localStorage.getItem(`${namespace}-sorting`);
    if (sort) setSorting(JSON.parse(sort));

    const filter = localStorage.getItem(`${namespace}-filter`);
    if (filter) setFilter(JSON.parse(filter));
  }, [namespace]);

  return (
    <div className={classes.root}>

    <MuiDataGrid
      sortModel={sorting}
      onSortModelChange={(model) => {
        setSorting(model);
        localStorage.setItem(`${namespace}-sorting`, JSON.stringify(model));
      }}
      filterModel={filter}
      onFilterModelChange={(model) => {
        setFilter(model);
        localStorage.setItem(`${namespace}-filter`, JSON.stringify(model));
      }}
      {...props}
    />
    </div>
  );
}

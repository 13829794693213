const LotteryCard = ({ lottery }: any) => {

    return (
      <label className="lotteryext">
        <br />
        <input
          className="radio"
          type="radio"
          name="lotteryID"
          value={lottery.lotteryID}
        />
        {lottery.lotteryEnglishMame}
        <br />
        <span className="hebrew">{lottery.lotteryHebrewMame}</span>
        <br />
        <span className="shops-text strong">{lottery.lotteryHebrewMame}</span>
        <span className="hebrew shops-text strong">
          {lottery.lotteryHebrewDesc}
        </span>
        {/*eslint-disable-next-line */}
        <img
          className="lottery"
          src={lottery.imagePath}
        />
      </label>
    );
  };
  
  export default LotteryCard;
import React, { useEffect, useState } from "react";
import fetcher from "../../../utils/fetcher";
import config from "../../../config";
import {
    DataGridPro,
    useGridApiRef,
    GridValueGetterParams,
    GridToolbarExport,
} from '@mui/x-data-grid-pro'
import { Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteButton from "../../DeleteButton";
import { remove } from "./remove";

export enum GridColumn {
    DisplayName = 'displayName',
    Email = 'email',
    Phone = 'phone',
    Department = 'department',
    Office = 'office',
    TestType = 'testType',
    Actions = 'actions',
}

const generateColumns = (props: any, departments: any) => [
    {
        field: GridColumn.DisplayName,
        headerName: "User Name",
        width: 330.33,
        valueGetter: (params: GridValueGetterParams) => {
            const { firstName, lastName } = params.row.employee;
            return `${lastName}, ${firstName}`;
        },
    },
    {
        field: GridColumn.Email,
        headerName: "E-mail",
        width: 330.33,
        valueGetter: (params: GridValueGetterParams) => params.row.employee.mail,
        renderCell: (params: any) => {
            const email = params.row.employee.mail;
            return <Link href={`mailto:${email}`} to={""}>{email}</Link>;
        },
    },
    {
        field: GridColumn.Office,
        headerName: "Office",
        width: 160,
        valueGetter: (params: GridValueGetterParams) => {
            const { office } = params.row.employee;
            return office.officeName;
        },
    },
    {
        field: GridColumn.Department,
        headerName: "Department",
        width: 160,
        valueGetter: (params: GridValueGetterParams) => {
            const { departmentID } = params.row.employee;
            const department = departments.find((d: any) => d.key === departmentID);
            return department?.value;
        },
    },
    {
        field: GridColumn.Actions,
        headerName: "",
        sortable: false,
        disableExport: true,
        width: 101,
        renderCell: (params: any) => {
            return (
                <DeleteButton
                    onClick={() => remove(params.row.shlCycleID, [params.row.employeeID], props.setReload)}
                ></DeleteButton>
            );
        },
    },
];

const getRowId = (row: any) => row.employee.employeeID;

const useStyles = makeStyles((theme) =>
({
    root: {
        fontSize: "12px",
        boxShadow: "0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03)",
        border: "none",
        borderRadius: "4px",
        marginTop: theme.spacing(5)
    },
})
);

export default function ParticipantsTable(props: any) {
    const { /*setIds,*/ rows } = props;
    const [departments, setDepartments] = useState<any>([]);
    const classes = useStyles();
    const apiRef = useGridApiRef()


    useEffect(() => {
        fetcher
            .get(`${config.apiUrl}/CodeTable/GetDepartmentList`)
            .then((response) => response.json())
            .then((data) => {
                setDepartments(data);
            });
    }, []);

    return (
        <Box sx={{ mt: 2, width: '100%', height: "100vh" }}>
            <DataGridPro
                className={classes.root}
                rows={rows}
                columns={generateColumns(props, departments)}
                filterModel={props.filterModel}
                apiRef={apiRef}
                pagination
                pageSize={5}
                getRowId={getRowId}
                components={{
                    Toolbar: () => <GridToolbarExport printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }} />
                }}

            />
        </Box>
    );
}
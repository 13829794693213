import { Button } from "@mui/material";

export const EditButton = (props: any) => {
  return (
    <Button 
      aria-label="edit" {...props}>
      Edit
    </Button>
  );
};
export default EditButton;

import React, { Fragment, useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    makeStyles,
    Menu,
    MenuItem,
    Button,
    Tabs,
    Tab,
    Grid
} from '@material-ui/core';
import { ArrowDropDown } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { ShlCycleMenue, GeneralSettingsMenue } from './Menue'

const useStyles = makeStyles((theme) => ({
    appbar: {
        backgroundColor: '#2C4FEC'
    },
    toolbar: {
        height: theme.spacing(10)
    },
    title: {
        display: "inline-flex",
        marginLeft: theme.spacing(2.5)
    },
    tabs: {
        marginLeft: theme.spacing(7.75)
    },
    tab: {
        textTransform: 'none',
        fontSize: theme.spacing(1.875),
        color: theme.palette.primary.contrastText,
        minWidth: 'fit-content',
        margin: theme.spacing(1),
        padding: 0
    },
    root: {
        height: theme.spacing(6.25),
    },
    selected: {
        height: theme.spacing(6.25),
        backgroundColor: 'rgba(32, 238, 179, 0.2)',
        borderRadius: theme.spacing(0.5),
        color: 'rgba(32, 238, 179, 1)'
    },
    label: {
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5)
    },
    userButton: {
        color: theme.palette.primary.contrastText,
        textTransform: 'none'
    },
    userName: {
        fontSize: theme.spacing(1.875),
        marginRight: theme.spacing(1),
        letterSpacing: '0.46px'
    },
    loginButton: {
        fontWeight: 550
    },
    contrastBackground: {
        backgroundColor: theme.palette.primary.contrastText
    },
}));

const HeaderNavbar = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [HYMenue, setHYMenue] = useState(null);
    const isOpen = Boolean(HYMenue);
    const [GSMenue, setGSMenue] = useState(null);
    const Open = Boolean(GSMenue);

    const navigations = [
        { text: 'Chagim Gift', to: '/events/2' },
        { text: 'Syum Raffle', to: '/events/1' },
        { text: 'Calendar', to: '/calendar' },
    ]

    const [value, setValue] = useState(_ => {
        let index = navigations.findIndex(n => n.to === window.location.pathname);
        return index > 0 ? index : 0;
    }
    );
    const { user, login, logout } = useAuth();

    useEffect(() => {
        let hideWelcomeModal = localStorage.getItem('hideWelcomeModal');
        if (!hideWelcomeModal) {
            localStorage.setItem('hideWelcomeModal', '1');
        }
    }, []);

    const onUserButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onLoginClick = () => {
        login();
        setAnchorEl(null);
    }

    const onLogoutClick = () => {
        logout();
        setAnchorEl(null);
    }

    const onCloseMenu = () => {
        setAnchorEl(null);
    }

    const handleClose = (name) => {
        console.log(name)
        setHYMenue(null);
        setGSMenue(null);
    }

    const handleClick = (event) => {
        setHYMenue(event.currentTarget);
    };

    const onClick = (event) => {
        setGSMenue(event.currentTarget);
    };

    const onNavigate = (_, newValue) => {
        setValue(newValue);
    }

    return (
        <Fragment>
            <AppBar
                position="sticky"
            >
                <Toolbar className={classes.toolbar}>
                    <Grid container>
                        <Grid item xs={2} container display="flex" alignItems="center">
                            <Box className={classes.title}>
                            </Box>
                        </Grid>
                        <Grid item xs={8} container display="flex" alignItems="center" justifyContent="center">
                            {user &&
                                <Tabs
                                    value={value}
                                    onChange={onNavigate}
                                    className={classes.tabs}
                                    aria-label="navigation-tabs"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        }
                                    }}>
                                    <Tab
                                        label={"Halacha Yomit"}
                                        component={Link}
                                        className={classes.tab}
                                        onClick={handleClick}
                                        classes={{
                                            root: classes.root,
                                            selected: classes.selected,
                                            wrapper: classes.label
                                        }}>
                                    </Tab>
                                    <ShlCycleMenue HYMenue={HYMenue} isOpen={isOpen} handleClose={handleClose} ></ShlCycleMenue>
                                    {Object.entries(navigations).map(([key, value]) =>
                                        <Tab
                                            key={key}
                                            label={value.text}
                                            to={value.to}
                                            component={Link}
                                            className={classes.tab}
                                            classes={{
                                                root: classes.root,
                                                selected: classes.selected,
                                                wrapper: classes.label
                                            }} />
                                    )}
                                    <Tab
                                        label={"General Settings"}
                                        component={Link}
                                        className={classes.tab}
                                        onClick={onClick}
                                        classes={{
                                            root: classes.root,
                                            selected: classes.selected,
                                            wrapper: classes.label
                                        }}>
                                    </Tab>
                                    <GeneralSettingsMenue GSMenue={GSMenue} Open={Open} handleClose={handleClose}></GeneralSettingsMenue>
                                </Tabs>
                            }
                        </Grid>
                        <Grid item xs={2} container display="flex" alignItems="center" justifyContent="flex-end">
                            {user ?
                                <Box >
                                    <Button className={classes.userButton} onClick={onUserButtonClick}>
                                        <Typography className={classes.userName}>
                                            Hi, {user?.givenName}!
                                        </Typography>
                                        <ArrowDropDown />
                                    </Button>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        style={{ top: "6vh" }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        open={open}
                                        onClose={onCloseMenu}
                                    >
                                        <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                                    </Menu>
                                </Box>
                                :
                                <MenuItem
                                    onClick={onLoginClick}>Login</MenuItem>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Fragment>
    )
}

export default HeaderNavbar;
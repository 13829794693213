import Participant from "../../entities/Participant";
import { IconButton, Tooltip } from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import DeleteButton from "../DeleteButton";
import { remove } from "./remove";

export interface ParticipantActionsProps {
  participant: Participant;
  edit: (participant: Participant) => {};
  setReload: (fetch: boolean) => {};
}

const ParticipantActions = ({
  participant,
  edit,
  setReload
}: ParticipantActionsProps) => {
 
  return (
    <div
      className="d-flex justify-content-between align-items-right"
      style={{ cursor: "pointer" }}
    >
      <Tooltip title={`Select a gift for ${participant.employee.firstName}`} placement="top">
        <IconButton aria-label="select" onClick={() => {
              edit(participant);
            }}>
          <CardGiftcardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={`Remove ${participant.employee.firstName} from this event`} placement="top">
        <DeleteButton  onClick={() => remove(participant.eventID, [participant.employeeID], setReload)}>
        </DeleteButton>
      </Tooltip>
    </div>
  );
};

export default ParticipantActions;

import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) =>
({
    root: {
        height:theme.spacing(5.25),
        width:theme.spacing(17.875),
        borderRadius: "4px",
        padding: "8px, 22px, 8px, 22px"
    },
    toolbar: {
        display: "none",
    },
})
);


// left: 0px;
// top: 0px;
// border-radius: 4px;
// padding: 8px, 22px, 8px, 22px;


const AddButton = ({ setOpen, children }: any) => {

const classes=useStyles();
  return (
    <>
      <Button
      className={classes.root}
        variant="contained"
        disableElevation
        size="medium"
        color="primary"
        onClick={()=> setOpen(true)}
      >
        <AddIcon />
        {children}
      </Button>
    </>
  );
};

export default AddButton;
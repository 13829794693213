import React, { useEffect, useState } from "react";
import AddButton from "../AddButton";
import GiftsTable from "./GiftsTable";
import { Grid } from "@material-ui/core";
import GiftEditor from "./GiftEditor";
import ExportButton from "../ExportButton";
import config from "../../config";

const GiftsList = ({ eventId }: any) => {
  const [reload, setReload] = useState(true);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [gift, setGift] = useState<any>({ eventID: eventId, order: count + 1 });

  useEffect(() => {
    setGift({ eventID: eventId, order: count + 1 });
  }, [count, eventId])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item>
            <ExportButton href={`${config.apiUrl}/HolidayGift/GetGiftByOfficeReport?eventID=${eventId}`}>Export Summary</ExportButton>
          </Grid>
          <Grid item >
            <AddButton setOpen={setOpen}>Add Gift</AddButton>
          </Grid>
        </Grid>
        <GiftsTable
          eventId={eventId}
          reload={reload}
          setReload={setReload}
          setOpen={setOpen}
          setGift={setGift}
          setCount={setCount}
        ></GiftsTable>
      </Grid>
      <GiftEditor
        open={open}
        onClose={() => {
          setGift({ eventID: eventId, order: count + 1 });
          setOpen(false);
        }}
        onSave={() => {
          setGift({ eventID: eventId, order: count + 1 });
          setOpen(false);
          setReload(true);
        }}
        gift={gift}
      ></GiftEditor>
    </>
  );
};

export default GiftsList;
import React, { useState } from "react";
import {
    createStyles,
    IconButton,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import Modal from "../Modal";
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import LotteryParticipantsTable from "./LotteryParticipantsTable";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontSize: "16px",
            Color: "primary",
        },
    })
);

const LotteryParticipants = ({ lotteryId }: any) => {
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(true);

    const classes = useStyles();

    return (
        <>
            <Tooltip title="Raffle participant" placement="top">
                <IconButton className={classes.root} onClick={() => setOpen(true)}  >
                    <PeopleRoundedIcon color="primary" />
                    Participant
                </IconButton>
            </Tooltip>
            <Modal
                isHide={false}
                title="Participant"
                open={open}
                maxWidth="lg"
                onClose={() => setOpen(false)}
            >
                <LotteryParticipantsTable
                    lotteryId={lotteryId}
                    reload={reload}
                    setReload={setReload}
                >
                </LotteryParticipantsTable>
            </Modal>
        </>
    );
};

export default LotteryParticipants;

import { Button, ButtonProps } from "@mui/material";

const DeleteButton = (props: ButtonProps) => {
  return (
    <Button
      aria-label="delete"
      color="primary"
      {...props} >
      Delete
    </Button>
  );
};
export default DeleteButton;

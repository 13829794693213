import { Tooltip } from "@material-ui/core";
import ExportButton from "./ExportButton";


export default function ExportCsv() {
  return (
    <Tooltip title="Export Excel" placement="top">
    <ExportButton
    onClick={() => {
        const button = document.querySelector('button[aria-label="Export"]');
        const id = button.getAttribute("aria-labelledby")
        button.click();
        setTimeout(()=> {
        document.querySelector(`#${id} li span`).click()
        }, 0);
    }}>
      Export
    </ExportButton>
    </Tooltip>
  );
}


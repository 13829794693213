import Alert from '@material-ui/lab/Alert';
import { Button } from "@material-ui/core";

const OutOfStockAlert=({isSaveSelectGift}:any)=>{
  return (
      <Alert severity="warning"
        action={
          <>
            <Button onClick={() => isSaveSelectGift(true)} color="inherit" size="small">
              Yes
            </Button>
            <Button onClick={() => isSaveSelectGift(false)} color="inherit" size="small">
              No
            </Button>
          </>
        }
      >
        This item is out of stock, are you sure you want to choose it?
      </Alert>
  )
}
export default OutOfStockAlert;


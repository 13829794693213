import * as React from 'react'
import {createTheme, ThemeProvider} from '@mui/material/styles'

function Styles({children}: any) {
  return (
    <ThemeProvider
      theme={createTheme({
          typography: {
            button: {
                textTransform: 'none'
            }
          }
      })}
    >
      {children}
    </ThemeProvider>
  )
}

export default Styles

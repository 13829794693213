export default {
    authConfig: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
        authority: process.env.REACT_APP_AAD_AUTHORITY || '',
        redirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',

      },
    isProduction: false,
    apiUrl: process.env.REACT_APP_API_URL,
    lotteryUrl:process.env.REACT_APP_LOTTERY_URL
};
import React, { useEffect, useState } from "react";
import AddButton from "../AddButton";
import { Grid } from "@material-ui/core";
import LotteryEditor from "./LotteryEditor";
import LotteriesTable from "./LotteriesTable";
import ExportCsv from "../ExportCsv";

const LotteriesList = ({ eventId }: any) => {
  const [reload, setReload] = useState(true);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [lottery, setLottery] = useState<any>({eventID: eventId, order: count+1});

  useEffect(() => {
    setLottery({eventID: eventId, order: count+1});
  }, [count, eventId])

  return (
    <>
      <Grid container spacing={2}>
      <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item>
            <ExportCsv />
          </Grid> 
          <Grid item >
            <AddButton setOpen={setOpen}>Add Raffle</AddButton>
          </Grid>
        </Grid>
        <LotteriesTable
          eventId={eventId}
          reload={reload}
          setReload={setReload}
          setOpen={setOpen}
          setLottery={setLottery}
          setCount={setCount}
        ></LotteriesTable>
      </Grid>
      <LotteryEditor
        open={open}
        onClose={() => {
          setLottery({eventID: eventId, order: count+1});
          setOpen(false);
        }}
        onSave={() => {
          setLottery({eventID: eventId, order: count+1});
          setOpen(false);
          setReload(true);
        }}
        lottery={lottery}
      ></LotteryEditor>
    </>
  );
};

export default LotteriesList;
import { Button } from "@material-ui/core";
import React from "react";
import config from "../config";

const LotteryButton = ({ eventId }: any) => {
    return (
        <>
            <Button
                variant="contained"
                disableElevation
                size="small"
                color="primary"
                href={`${config.lotteryUrl}/all-lotteries/${eventId}`}
            >
                Raffle
            </Button>
        </>
    );
};

export default LotteryButton;

import './Calendar.css'
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import { Grid, IconButton, Typography } from '@material-ui/core';

export default function renderSidebar(props: any) {
    const { currentEvents } = props
    let icon: any = 0;
    return (
        <div id="events">
            <h2>Upcoming Events</h2>
            <Grid className='calendar-sidebar'>
                <Grid justifyContent="flex-start"
                    alignItems="flex-end" className='calendar-sidebar-section'>
                    <ul>
                        {currentEvents.map((event: any, key: any) => {
                            if (key >= currentEvents.length - 3) {
                                switch (event.calenderEventType) {
                                    case 1:
                                        icon = <CardGiftcardOutlinedIcon />
                                        break;
                                    case 2:
                                        icon = <CardGiftcardOutlinedIcon />
                                        break;
                                    case 3 || 4:
                                        icon = <CardGiftcardOutlinedIcon />
                                        break;
                                }
                                return (
                                    <>
                                        <Grid key={event.calenderEventType}
                                            container spacing={2}>
                                            <Grid item>
                                                <IconButton>
                                                    {icon}
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} sm container>
                                                <Grid item xs container direction="column" spacing={2}>
                                                    <Grid item xs>
                                                        <Typography>
                                                            {event.title}
                                                        </Typography>
                                                        <Typography>
                                                            {/* {formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })} */}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }
                            return (<></>)
                        }
                        )}
                    </ul>
                </Grid>
            </Grid>
        </div>
    )
}
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../context/Auth";
import LoginButton from "./LoginButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  submit: {
    borderColor: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(3),
  },
}));

export default function Login() {
  let location = useLocation();

  const classes = useStyles();

  const { isAuth } = useAuth();

  const { from } = (location.state as any) || { from: { pathname: "/" } };

  // if we have a user, we shouldn't be at this url
  if (isAuth) return <Redirect to={from} />;

  return (
      <Box textAlign="center" position="static" className={classes.appBar}>
        <Typography component="h1" variant="h2">
          Gifts Backoffice
        </Typography>
        <LoginButton  />
      </Box>
  );
}

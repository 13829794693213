const get = async (endpoint: string, options: RequestInit = {}) => {
  const mergedOpts = {...options, method: 'GET'}
  return await fetcher(endpoint, mergedOpts)
}

const getJson = async (endpoint: string, options: RequestInit = {}) => {
  const mergedOpts = {...options, method: 'GET'}
  const res = await fetcher(endpoint, mergedOpts)
  return await res.json()
}

const post = async (endpoint: string, options: RequestInit = {}, addContentType = true) => {
  const headers = addContentType && {
    'Content-Type': 'application/json'
  };
  const mergedOpts = {
    ...headers,
    ...options,
    method: 'POST'
  }
  return await fetcher(endpoint, mergedOpts)
}

const put = async (endpoint: string, options: RequestInit = {}, addContentType = true) => {
  const headers = addContentType && {
    'Content-Type': 'application/json'
  };
  const mergedOpts = {
    ...headers,
    ...options,
    method: 'PUT'
  }
  return await fetcher(endpoint, mergedOpts)
}

const del = async (endpoint: string, options: RequestInit = {}) => {
  const mergedOpts = {
    ...options,
    method: 'DELETE'
  }
  return await fetcher(endpoint, mergedOpts)
}

const use = (interceptor: (options: RequestInit) => Promise<RequestInit>) => {
  interceptors.push(interceptor)
}

const interceptors: ((options: RequestInit) => Promise<RequestInit>)[] = []
use(options => {
  return Promise.resolve({
    ...options
  })
})

const fetcher = async (endpoint: RequestInfo, options: RequestInit) => {
  let mergedOpts = {...options}
  for (let i = 0; i < interceptors.length; i++) {
    mergedOpts = {
      ...mergedOpts,
      ...(await interceptors[i]({...mergedOpts}))
    }
  }

  return fetch(endpoint, mergedOpts).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status)
    }
    return response
  })
}

export default {
  get,
  getJson,
  post,
  put,
  del,
  fetch: fetcher,
  use
}

import React from 'react';
// import config from '../../config';
import Event from '../../entities/Event';
// import fetcher from '../../utils/fetcher';
import EditButton from '../EditButton';

export interface EventActionsProps{
    event: Event,
    edit: (event:Event) => {},
    setReload: (fetch:boolean) =>{}
}



const EventActions = ({event, edit, setReload} : EventActionsProps) => {

    return (
        <div
          className="d-flex justify-content-between align-items-right"
          style={{ cursor: "pointer" }}
        >
          {/* {event.isLock ? '' : */}
          <EditButton
            onClick={() => {
              edit(event);
            }}
          />
          {/* } */}
        </div>
    );
}

export default EventActions;
import config from "../../config";

const GiftCard = ({ gift }: any) => {

  return (
    <label className="giftext">
      <br />
      <input
        className="radio"
        type="radio"
        name="holidayGiftID"
        value={gift.holidayGiftID}
      />
      {gift.holidayGiftName}
      <br />
      <span className="hebrew">{gift.holidayGiftNameHeb}</span>
      <br />
      <span className="shops-text strong">{gift.holidayGiftDescription}</span>
      <span className="hebrew shops-text strong">
        {gift.holidayGiftDescriptionHeb}
      </span>
      {/*eslint-disable-next-line */}
      <img
        className="gift"
        src={`${config.apiUrl}/Image/GetImage/${gift.imageID}`}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: gift.htmlDescription,
        }}
      ></div>
    </label>
  );
};

export default GiftCard;

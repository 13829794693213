import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const EditButton = (props: any) => {
  const { isLock, onChange } = props;
  const [lock, setLock] = useState(isLock);

  useEffect(() => {
    setLock(isLock);
  }, [isLock]);
  return (
    <IconButton
      aria-label="edit"
      onClick={() => {
        onChange?.(!lock);
        setLock(!lock);
      }}
      {...props}
    >
      {lock ? <LockIcon /> : <LockOpenIcon />}
    </IconButton>
  );
};

export default EditButton;

import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { AuthContextProvider } from "./context/Auth";
import Loading from "./components/Loading";
import Logout from "./components/auth/Logout";
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import EventsList from "./components/events/EventsList";
import EventPage from "./components/event/EventPage";
import LevelPage from "./components/shlCycle/levels/LevelPage";
import LotteryPape from './components/lotteries/LotteryPage'
import HeaderNavbar from "./components/appBar/HeaderNavbar";
import LevelsList from "./components/shlCycle/levels/LevelsList";
import  Calendar  from '../src/components/calendar/Calendar'
import ParticipantsList from "../src/components/shlCycle/participants/ParticipantsList"
import TestsList from "./components/shlCycle/levels/tests/TestsList";
import Styles from "./components/Styles";

function App() {
  return (
    <Styles>
      <AuthContextProvider>
        <BrowserRouter>
          <HeaderNavbar />
          <Suspense fallback={<Loading />}>
            <Switch>
              {/* <ProtectedRoute exact path="/events/:typeId">
                <EventsList></EventsList>
              </ProtectedRoute>
              <ProtectedRoute path="/events/:id">
                <EventPage></EventPage> 
              </ProtectedRoute> 
              <ProtectedRoute path="/lottery/:id">
                <LotteryPape></LotteryPape>
              </ProtectedRoute> */}
              <ProtectedRoute exact path="/events/:typeId">
                <EventsList></EventsList>
              </ProtectedRoute>
              <ProtectedRoute path="/gift/:id">
                <EventPage></EventPage>
              </ProtectedRoute>
              <ProtectedRoute path="/levelPage/:levelId">
                <LevelPage></LevelPage>
              </ProtectedRoute>
              <ProtectedRoute path="/lottery/:id">
                <LotteryPape></LotteryPape>
              </ProtectedRoute>
              <ProtectedRoute path="/levels">
                <LevelsList></LevelsList>
              </ProtectedRoute>
              <ProtectedRoute path="/calendar">
                  <Calendar></Calendar>
                </ProtectedRoute>
              <ProtectedRoute path="/shlCycleParticipant">
                <ParticipantsList></ParticipantsList>
              </ProtectedRoute>
              <ProtectedRoute path="/examination">
                <TestsList></TestsList>
              </ProtectedRoute>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
              <Redirect exact from="/" to="/events" />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </AuthContextProvider>
    </Styles>
  );
}

export default App;

import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { createTheme, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      filter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
        },
        margin: theme.spacing(-0.5, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        '& .MuiInputBase-root': {
          height: "37px"
        },
        position: "static",
        display: "inherit",
        width: theme.spacing(45),

      },
      div: {
        top: "-50px !important",
      }
    }),
  { defaultTheme }

);

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: (e: any) => void;
  value: string;
  placeholder?:string
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const classes = useStyles();
  return (
    <div className={classes.filter}>
      <TextField
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={classes.textField}
        InputProps={{
          endAdornment: <SearchIcon fontSize="small"  />,
        }}
      />
    </div>
  );
}
export default QuickSearchToolbar;
import React from "react";
import {
    Menu,
    MenuItem,
} from '@material-ui/core';
import { Link } from "react-router-dom";

export function ShlCycleMenue(props: any) {
    const { HYMenue, isOpen, handleClose } = props

    return (
        <>
        <Menu
            id="menu1"
            anchorEl={HYMenue}
            open={isOpen}
            onClose={handleClose}
            style={{top: "6vh"}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem component={Link} to={"/calendar"} onClick={() => handleClose("Dashboard")}>Dashboard</MenuItem>
            <MenuItem onClick={() => handleClose("Library")}>Library</MenuItem>
            <MenuItem component={Link} to={"/levels"} onClick={() => handleClose("Levels")}>Levels</MenuItem>
            <MenuItem component={Link} to={"/shlCycleParticipants/1"} onClick={() => handleClose("Participants")}>Participants</MenuItem>
            <MenuItem component={Link} to={"/examination"} onClick={() => handleClose("Test List")}>Test List</MenuItem>
            <MenuItem component={Link} to={"/events/1"} onClick={() => handleClose("Weekly Raffle")}>Raffles</MenuItem>
            <MenuItem onClick={() => handleClose("Archive")}>Archive</MenuItem>
        </Menu>
        </>
    )
}

export function GeneralSettingsMenue(props: any) {
    const { GSMenue, Open, handleClose } = props

    return (
        <Menu
            id="menu"
            anchorEl={GSMenue}
            open={Open}
            onClose={handleClose}
            style={{top: "6vh"}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={() => handleClose("GSLibrary")}>Library</MenuItem>
            <MenuItem onClick={() => handleClose("GSPlans")}>Levels</MenuItem>
            <MenuItem onClick={() => handleClose("Scheduling")}>Scheduling</MenuItem>
        </Menu>
        
    )
}


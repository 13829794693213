import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import config from "../../../config";
import fetcher from "../../../utils/fetcher";
import Modal from "../../Modal";
import { DataGrid, GridValueGetterParams } from "@material-ui/data-grid";
import { Participant } from "@microsoft/microsoft-graph-types";
import QuickSearchToolbar from '../../QuickSearchToolbar'
//import ExaminationTypeSelect from "../../ExaminationTypeSelect";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      "& > *": {
        margin: spacing(1),

      },
    },
    fullWidth: {
      width: `calc(100% - ${spacing(2)}px)`,
    },
    datagrid: {
      margin: spacing(1),
      boxShadow: "0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03)",
      border: "none",
      borderRadius: "4px",
    }
  })
);

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const AddParticipant = ({levelId, shlCycleId, setReload, reload, setIds, ids }: any) => {
  const [open, setOpen] = useState(false);
  const [participants, setParticipants] = useState<any>();
  //const [examinationTypeId,setExaminationTypeId] = useState<any>(0);
  const [rows, setRows] = useState<Participant[]>(participants);

  const getRowId = (row: any) => row.employeeID

  useEffect(() => {
    if (reload) {
      const url = `${config.apiUrl}/SHLCycle/GetEmployeeListNotInSHLCycle?${shlCycleId}`
      fetcher
        .get(url)
        .then((response) => response.json())
        .then((data) => setParticipants(data));
      setReload(false);
    }
  }, [reload, setReload, shlCycleId]);

  useEffect(() => {
    setRows(participants);
  }, [participants]);

  const classes = useStyles();

  const create = () => {
    const url = `${config.apiUrl}/SHLCycle/AddEmployeesToSHLCycleByLevelId?employeeID=${ids}&examinationTypeId=${levelId}`;
    fetcher
      .post(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((_) => {
        setReload(true);
        setOpen(false)
      });
  };

  const fieldsName = ['firstName', 'lastName', 'mail']
  const [searchText, setSearchText] = useState('');

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = participants.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && fieldsName.includes(field) && searchRegex.test(row[field])
      });
    });
    setRows(filteredRows);
  };

  return (
    <>
      <Tooltip title="Adds single participant" placement="top">
        <Button
          variant="contained"
          disableElevation
          size="medium"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          Add Participant
        </Button>
      </Tooltip>

      <Modal
        title="Add Participant"
        open={open}
        onSave={create}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <Grid  justifyContent="flex-end">
          <QuickSearchToolbar
            value={searchText}
            onChange={(event: any) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')} />
        </Grid>
        <form className={classes.root}>
          <DataGrid
            className={classes.datagrid}
            columns={[
              {
                field: "displayName",
                headerName: "Name",
                width: 250,
                valueGetter: (params: GridValueGetterParams) => {
                  const { firstName, lastName } = params.row;
                  return `${lastName}, ${firstName}`;
                },
              },
              {
                field: "email",
                headerName: "Email",
                width: 250,
                valueGetter: (params: GridValueGetterParams) => params.row.mail,
                renderCell: (params: any) => {
                  const email = params.row.mail;
                  return <Link href={`mailto:${email}`}>{email}</Link>;
                },
              },
            ]}
            rows={rows}
            getRowId={getRowId}
            disableMultipleSelection={true}
            style={{ height: "55vh", width: '100%' }}
            onSelectionModelChange={setIds}
          />
        </form>
        {/* <ExaminationTypeSelect endpoint={"GetExaminationTypeList"} examinationTypeId={examinationTypeId} onChange={(id) => { setExaminationTypeId(id) }}></ExaminationTypeSelect> */}
      </Modal>
    </>
  );
};

export default AddParticipant;
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    title: {
      margin: 0,
      padding: spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: spacing(1),
      top: spacing(1),
    },
  })
);

export default function Modal(props: any) {
  const { children, ...dialogProps } = props;
  const { title, onSave, onClose ,disabled} = dialogProps;
  const classes = useStyles();
  
  return (
    <Dialog {...dialogProps} fullWidth>
      <DialogTitle className={classes.title}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {props.isHide ??
        <>
          <DialogActions>
            <Button onClick={onClose} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={onSave ?? onClose}
              disabled={disabled}
            >
              {onSave ? "Save" : "Ok"}
            </Button>
          </DialogActions>
        </>}
    </Dialog>
  );
}

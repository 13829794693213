import React, { useState } from "react";
import ParticipantsTable from "./ParticipantsTable";
import { Grid } from "@material-ui/core";
import AddParticipant from "./AddParticipant";
import ExportCsv from "../ExportCsv";
import RemoveParticipants from "./RemoveParticipants";
import { GridRowId } from "@material-ui/data-grid";

const ParticipantsList = ({ eventId }: any) => {
  const [reload, setReload] = useState(true);
  const [ids, setIds] = useState<GridRowId[]|string[]>([]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item>
            <AddParticipant eventId={eventId} setReload={setReload} />
          </Grid>
          <Grid item>
            <ExportCsv />
          </Grid>
          <Grid item>
            <RemoveParticipants ids={ids} eventId={eventId} setReload={setReload}/>
          </Grid>
        </Grid>
        <ParticipantsTable
          eventId={eventId}
          reload={reload}
          setReload={setReload}
          setIds={setIds}
        ></ParticipantsTable>
      </Grid>
    </>
  );
};

export default ParticipantsList;
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import config from "../config";
import fetcher from "../utils/fetcher";
import Gift from "../entities/Gift";

interface Props {
  eventId: number;
  giftId: number;
  onChange: (id: any) => void;
}

const GiftSelect = ({  giftId, eventId, onChange }: Props) => {
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [selected, setSelected] = useState<number>(giftId);


  useEffect(() => {
    const url = `${config.apiUrl}/HolidayGift/GetHolidayGiftList/${eventId}`;
    fetcher
      .get(url)
      .then((response) => response.json())
      .then((data) => setGifts(data));
  }, [eventId]);

  return (
    <>
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel>Selected Gift</InputLabel>
        <Select
          required
          value={ giftId?selected:"" }
          onChange={(e: any) => {
            const value = e.target.value;
            setSelected(value);
            onChange(gifts.find(g => g.holidayGiftID === value));


          }}
          label="Selected Gift"
        >
          {gifts.map((gift: Gift) => (
            <MenuItem key={gift.holidayGiftID} value={gift.holidayGiftID} >
              {gift.holidayGiftName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default GiftSelect;

import React from "react";
import { Button } from "@material-ui/core";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

interface Props {
  onClick?: () => void;
  children: any;
  variant?: string;
  href?: string;
}

const ExportButton = ({ onClick, children, href }: Props) => {

  return (
    <Button
      variant="contained"
      disableElevation
      size="medium"
      color="primary"
      href={href}
      onClick={onClick}
    >
      <SaveAltIcon />
      {children}
    </Button>
  );
};

export default ExportButton;

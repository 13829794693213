import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import config from "../config";
import fetcher from "../utils/fetcher";
import Office from "../entities/Ofiice";

interface Props {
  defaultValue?: number;
  onChange?: (id: number) => void;
  disabled?: boolean;
  label?: string;
  endpoint?: string
}

const OfficeSelect = ({ defaultValue, onChange, disabled, label="Office", endpoint = "GetOfficeList" }: Props) => {
  const [offices, setOffices] = useState<Office[]>([]);
  const [selected, setSelected] = useState<number | undefined>(defaultValue);

  useEffect(() => {
    const url = `${config.apiUrl}/CodeTable/${endpoint}`;
    fetcher
      .get(url)
      .then((response) => response.json())
      .then((data) => setOffices(data));
  }, [endpoint]);

  return (
    <FormControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        value={selected}
        onChange={(e: any) => {
          const value = e.target.value;
          setSelected(value);
          onChange?.(value);
        }}
        label={label}
        disabled={disabled}
      >
        {offices.map((office: any) => (
          <MenuItem value={office.key}>{office.value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OfficeSelect;

import config from "../../config";
import Lottery from "../../entities/Lottery";
import fetcher from "../../utils/fetcher";
import DeleteButton from "../DeleteButton";
import EditButton from "../EditButton";

export interface LotteryActionsProps {
  setReload: (fetch: boolean) => {};
  setOpen: (fetch: boolean) => {};
  open: boolean;
  lottery: Lottery;
  setLottery: (lottery: Lottery) => void;
}

const LotteryActions = ({
  lottery,
  setOpen,
  setReload,
  setLottery,
}: LotteryActionsProps) => {
  const DeleteLottery = ({ lotteryID, selectionCount }: Lottery) => {
    if (selectionCount)
      alert("You can't delete a raffle that has been selected by employees");
    else if (
      window.confirm(`Are you sure you want to delete ${lottery.lotteryEnglishMame}`)
    ) {
      const url = `${config.apiUrl}/LiveLottery/DeleteLottery?lotteryID=${lotteryID}`;
      fetcher
        .post(url)
        .then(() => {
          setReload(true);
        })
        .catch((_) =>
          alert("You can't delete a gift that has been selected by employees")
        );
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-right"
        style={{ cursor: "pointer" }}
      >
        <EditButton color="primary"
          onClick={() => {
            setLottery(lottery)
            setOpen(true);
          }}
        />
        <DeleteButton
          disabled={lottery.selectionCount ? true : false}
          onClick={() => {
            DeleteLottery(lottery);
          }}
        />
      </div>
    </>
  );
};

export default LotteryActions; 
import React, { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Modal from "../Modal";
import fetcher from "../../utils/fetcher";
import config from "../../config";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Lottery from "../../entities/Lottery";
import LotteryCard from "./LotteryCard";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            "& > *": {
                margin: spacing(1),
                width: `calc(50% - ${spacing(2)}px)`,
            },
        },
        fullWidth: {
            width: `calc(100% - ${spacing(2)}px)`,
        },
    })
);

const isEdit = (lottery: Lottery) => {
    return lottery && lottery.lotteryID;
};

const getUrl = (lottery: Lottery) => {
    if (isEdit(lottery)) return `${config.apiUrl}/LiveLottery/UpdateLottery`;
    return `${config.apiUrl}/LiveLottery/AddLottery`;
};

const LotteryEditor = ({ lottery, onSave, ...props }: any) => {
    const [data, setData] = useState<any>(lottery);
    const [preview] = useState(false);

    const classes = useStyles();

    const setValue = (key: string, value: any) => {
        setData({
            ...data,
            [key]: value,
        });
    };

    useEffect(() => {
        const { ...fields } = lottery;
        setData(fields);
    }, [lottery]);

    const onSubmit = () => {
        const url = getUrl(data);
        fetcher
            .post(url, {
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(() => onSave(data));
    };

    return (
        <Modal
            maxWidth="md"
            title={isEdit(lottery) ? "Edit Raffle" : "Add Raffle"}
            onSave={onSubmit}
            {...props}
        >
            <Grid container>
                <Grid>
                    <form className={classes.root}>
                        <TextField
                            name="lotteryEnglishMame"
                            variant="outlined"
                            label="Raffle Name"
                            defaultValue={data.lotteryEnglishMame}
                            onChange={(e) => setValue("lotteryEnglishMame", e.target.value)}
                        />

                        <TextField
                            dir="rtl"
                            name="lotteryHebrewMame"
                            variant="outlined"
                            label="שם"
                            defaultValue={data.lotteryHebrewMame}
                            onChange={(e) => setValue("lotteryHebrewMame", e.target.value)}
                        />

                        <TextField
                            variant="outlined"
                            multiline
                            label="Raffle Description"
                            name="lotteryEnglishDesc"
                            defaultValue={data.lotteryEnglishDesc}
                            onChange={(e) => setValue("lotteryEnglishDesc", e.target.value)}
                        />

                        <TextField
                            dir="rtl"
                            variant="outlined"
                            label="תיאור"
                            multiline
                            name="lotteryHebrewDesc"
                            defaultValue={data.lotteryHebrewDesc}
                            onChange={(e) => setValue("lotteryHebrewDesc", e.target.value)}
                        />

                        <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel>Raffle Type</InputLabel>
                            <Select
                                value={data.lotteryTypeID}
                                onChange={(e: any) => setValue("lotteryTypeID", e.target.value)}
                                label="Raffle Type"
                            >
                                <MenuItem value={1}>Live</MenuItem>
                                <MenuItem value={2}>Offline</MenuItem>
                                <MenuItem value={3}>Special</MenuItem>
                                <MenuItem value={4}>Monthly - Gift Card</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            dir="rtl"
                            variant="outlined"
                            label="Raffels Amount"
                            multiline
                            type="number"
                            name="lotteriesAmount"
                            defaultValue={data.lotteriesAmount}
                            onChange={(e) => setValue("lotteriesAmount", e.target.value)}
                        />

                        <TextField
                            name="lotteryNumber"
                            variant="outlined"
                            label="Raffle Number"
                            defaultValue={data.lotteryNumber}
                            onChange={(e) => setValue("lotteryNumber", e.target.value)}
                        />

                        <TextField
                            name="order"
                            variant="outlined"
                            label="Order"
                            defaultValue={data.order}
                            onChange={(e) => setValue("order", e.target.value)}
                        />
                    </form>
                </Grid>
                {preview && (
                    <Grid>
                        <LotteryCard lottery={data}></LotteryCard>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default LotteryEditor;
import React from "react";
import DataGrid from "../../DataGrid";
import { GridValueGetterParams } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core";
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import { Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import LevelActions from "./LevelActions";

const useStyles = makeStyles((theme) =>
({
    root: {
        fontSize: "12px",
        boxShadow: "0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03)",
        border: "none",
        borderRadius: "4px",
        marginTop: theme.spacing(5)
    },
})
);

const generateColumns = (props: any) => [
    { field: "examinationTypeID", headerName: "ID", hide: true },
    {
        field: "examinationTypeName",
        headerName: "Level",
        width: 336,
        renderCell: (params: any) => {
            return (
                <Link to={`/levelPage/${params.row.examinationTypeID}`}>{params.row.examinationTypeName}</Link>);
        },
    },
    {
        field: "participantsNumber",
        headerName: "Participants",
        width: 200,
        renderCell: (params: any) => {
            return <>
                <SupervisorAccountOutlinedIcon fontSize={"small"} color="primary" />
                <Typography color="primary">{"   " + params.row.participantsNumber}</Typography>
            </>;
        },
    },
    {
        field: "Book",
        headerName: "Book",
        width: 336,
        valueGetter: (params: GridValueGetterParams) => {
            const { isOpenBook } = params.row;
            return `${isOpenBook ? "Open " : "Closed "}`;
        },
    },
    {
        field: "testType",
        headerName: "Questions",
        width: 336,
        valueGetter: (params: GridValueGetterParams) => {
            const { isOpenQuestions } = params.row;
            return ` ${isOpenQuestions ? "Open Question" : "Closed Question"}`;
        },
    },
    {

        field: "",
        headerName: "",
        sortable: false,
        width: 140,
        renderCell: (params: any) => {
            return <LevelActions  examinationType={params.row} {...props}></LevelActions>;
        },
    },

];

const getRowId = (row: any) => row.examinationTypeID;

export default function LevelTable(props: any) {
    const { rows } = props;
    const classes = useStyles();

    return (
        <>
            <DataGrid
                className={classes.root}
                namespace="examinationType"
                rows={rows}
                columns={generateColumns(props)}
                getRowId={getRowId}
                checkboxSelection
                disableColumnMenu={true}
                disableSelectionOnClick
            >
            </DataGrid>
        </>
    );
}
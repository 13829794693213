import config from "../../config";
import fetcher from "../../utils/fetcher";

export function remove(
  firstName:any,
  eventId: number,
  ids: number[],
  setReload: (b: boolean) => void
) {
let bool=true
  
if(firstName){
  bool = window.confirm(`Are you sure you want to delete ${firstName} from the list `)
}
else{
  bool = window.confirm("Are you sure you want to delete all the select participants")
}
   


  const url = `${config.apiUrl}/LiveLottery/RemoveEmployeesToLotteryEvent?eventID=${eventId}`;
  if (bool) {
    fetcher
      .post(url, {
        body: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/json",
        },
      })
    .then((_) => {
      setReload(true);
    })
  }

}
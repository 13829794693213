import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import Modal from "../Modal";
import fetcher from "../../utils/fetcher";
import config from "../../config";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Gift from "../../entities/Gift";
import GiftCard from "./GiftCard";
import UploadButton from "../UploadButton";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      "& > *": {
        margin: spacing(1),
        width: `calc(50% - ${spacing(2)}px)`,
      },
    },
    fullWidth: {
      width: `calc(100% - ${spacing(2)}px)`,
    },
  })
);

const isEdit = (gift: Gift) => {
  return gift && gift.holidayGiftID;
};

const getUrl = (gift: Gift) => {
  if (isEdit(gift)) return `${config.apiUrl}/HolidayGift/UpdateHolidayGift`;
  return `${config.apiUrl}/HolidayGift/AddHolidayGift`;
};

const GiftEditor = ({ gift, onSave, ...props }: any) => {
  const [data, setData] = useState<any>(gift);
  const [preview] = useState(false);

  const classes = useStyles();

  const setValue = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  useEffect(() => {
    const { holidayGiftOptions, ...fields } = gift;
    setData(fields);
  }, [gift]);

  const onSubmit = () => {
    const url = getUrl(data);
    fetcher
      .post(url, {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => onSave(data));
  };

  return (
    <Modal
      maxWidth="md"
      title={isEdit(gift) ? "Edit Gift" : "Add Gift"}
      onSave={onSubmit}
      {...props}
    >
      <Grid container>
        <Grid>
          <form className={classes.root}>
            <TextField
              name="holidayGiftName"
              variant="outlined"
              label="Gift Name"
              defaultValue={data.holidayGiftName}
              onChange={(e) => setValue("holidayGiftName", e.target.value)}
            />

            <TextField
              dir="rtl"
              name="holidayGiftNameHeb"
              variant="outlined"
              label="שם"
              defaultValue={data.holidayGiftNameHeb}
              onChange={(e) => setValue("holidayGiftNameHeb", e.target.value)}
            />

            <TextField
              variant="outlined"
              multiline
              label="Gift Description"
              name="holidayGiftDescription"
              defaultValue={data.holidayGiftDescription}
              onChange={(e) =>
                setValue("holidayGiftDescription", e.target.value)
              }
            />
            <TextField
              dir="rtl"
              variant="outlined"
              label="תיאור"
              multiline
              name="holidayGiftDescriptionHeb"
              defaultValue={data.holidayGiftDescriptionHeb}
              onChange={(e) =>
                setValue("holidayGiftDescriptionHeb", e.target.value)
              }
            />
            <UploadButton
              setId={(id: number) => {
                setValue("imageID", id);
              }}
            />
            <TextField
              name="order"
              variant="outlined"
              label="Order"
              defaultValue={data.order}
              onChange={(e) => setValue("order", e.target.value)}
            />
            <TextField
              className={classes.fullWidth}
              variant="outlined"
              label="HTML Footer"
              multiline
              name="htmlDescription"
              defaultValue={data.htmlDescription}
              onChange={(e) => setValue("htmlDescription", e.target.value)}
            />
            <FormControlLabel
              control={
                <Switch
                  name="isOutOfStock"
                  color="primary"
                  defaultChecked={data.isOutOfStock}
                  onChange={(e) => setValue("isOutOfStock", e.target.checked)}
                />
              }
              label="Is Out Of Stock"
            />
            <FormControlLabel
              control={
                <Switch
                  name="isBackOfficeOnly"
                  color="primary"
                  defaultChecked={data.isBackOfficeOnly}
                  onChange={(e) =>
                    setValue("isBackOfficeOnly", e.target.checked)
                  }
                />
              }
              label="BackOffice Only"
            />
          </form>
        </Grid>
        {preview && (
          <Grid>
            <GiftCard gift={data}></GiftCard>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default GiftEditor;

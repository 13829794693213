import React from 'react'
import {
  CircularProgress,
  Typography,
  Grid,
  Box
} from '@material-ui/core'

interface LoadingClasses {
  container?: string
}

interface LoadingProps {
  message?: string | undefined
  disableMargin?: boolean
  color?: string
  size?: number
  classses?: LoadingClasses
}

export default function Loading({
  message,
  disableMargin,
  color,
  size,
  classses
}: LoadingProps) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Box color={color ? color : 'primary'}>
        <CircularProgress
          color={color ? 'inherit' : 'primary'}
          size={size || 70}
        />
      </Box>

      <Box color={color ? color : 'inherit'}>
        <Typography color="inherit">{message}</Typography>
      </Box>
    </Grid>
  )
}

import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { remove } from "./remove";

export default function RemoveParticipants({eventId, ids, setReload}:any){
    return (
    <Tooltip title="Remove all selected participants" placement="top">
    <Button  variant="contained"
        disableElevation
        size="medium"
        color="secondary"
        onClick={() => {
            remove(eventId, ids, setReload);
        }}>
          <DeleteIcon/>
          Remove
        </Button>
        </Tooltip>
    );
}
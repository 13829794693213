import React, { useEffect, useState } from "react";
import Event from "../../entities/Event";
import fetcher from "../../utils/fetcher";
import config from "../../config";
import DataGrid from "../DataGrid";
import { GridValueGetterParams } from "@material-ui/data-grid";
import EventActions from "./EventActions";
import LockButton from "../LockButton";
import { Link } from "react-router-dom";
import LotteryButton from "../LotteryButton";

const generateColumns = (props: any, typeId: any) => [
  { field: "eventID", headerName: "ID", hide: true },
  {
    field: "eventName",
    headerName: "Event name",
    width: 300,
    renderCell: (params: any) => {
      const url = params.row.eventTypeID === 2 ? `/gift` : `/lottery`
      return (
        <Link to={`${url}/${params.row.eventID}`}>{params.row.eventName}</Link>);

    },
  },
  {
    field: "eventDesc",
    headerName: "Description",
    width: 300,
  },
  {
    field: "eventDate",
    headerName: "Date",
    width: 300,
    valueGetter: (params: GridValueGetterParams) => {
      const eventDate = params.row.eventDate;
      return eventDate
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const isOpen = params.row.isEnabledSelection;
      return isOpen ? "Open" : "Closed";
    },
  },
  {
    field: "isLock",
    headerName: "Lock",
    renderCell: (params: any) => (
      <LockButton disabled isLock={params.row.isLock}></LockButton>
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 140,
    renderCell: (params: any) => {
      return <EventActions event={params.row} {...props} />;
    },
  },

];

const getRowId = (row: any) => row.eventID;

export default function EventsTable(props: any) {
  const { reload, setReload, typeId } = props;
  const [events, setEvents] = useState<Event[]>([]);
  const [columns, setColumns] = useState<any[]>(generateColumns(props, typeId));


  useEffect(() => {
    fetcher
      .get(`${config.apiUrl}/Event/GetEventList/?typeId=${typeId}`)
      .then((response) => response.json())
      .then((data) =>
        setEvents(data));
    setReload(false);

    const editableColumns = [...generateColumns(props, typeId)];
    if (typeId === "1") {
      editableColumns.push({
        field: "Raffle Link",
        headerName: "",
        sortable: false,
        width: 180,
        renderCell: (params: any) => {
          return <LotteryButton eventId={params.row.lotteryID} />
        },
      })
    }
    setColumns(editableColumns)
  }, [reload, setReload, typeId, props]);

  return (
    <DataGrid
      namespace="event"
      rows={events}
      columns={columns}
      getRowId={getRowId}
    />
  );
}
import React, { ReactType } from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Box, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(9),
    height: theme.spacing(101.625),
    borderRradius: theme.spacing(0),
  },
  large: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  child: {
    marginTop: theme.spacing(5)
  }
}))

interface MainContainerProps extends React.PropsWithChildren<{}> {
  isChild?: boolean
  large?: boolean
  title?: string
  lotteryButton?: any
  RowsNumber?: any
  subtitle?: string | ReactType
}

function Title({
  mainTitle,
  Subtitle,
  children
}: {
  mainTitle?: string
  Subtitle?: string | ReactType
  children?: any
}) {
  return (
    <Box mb={4} mt={4}>
      <Typography variant="h5">
        {mainTitle + "  "}
        {children}
        {Subtitle && (
          <Typography display="inline" variant="body1">
            {typeof Subtitle === 'string' ? Subtitle : <Subtitle />}
          </Typography>
        )}
      </Typography>
    </Box>
  )
}

export default function MainContainer(props: MainContainerProps) {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="space-around"
      className={props.isChild ? classes.child : classes.root}
    >
      <Grid
        item
        xs={props.large ? 12 : 11}
        className={props.large ? classes.large : ''}
      >
        {props.title && (
          <Title mainTitle={props.title} Subtitle={props.subtitle} >
            {props.lotteryButton}
          </Title>
        )}
        {props.children}
      </Grid>
    </Grid>
  )
}

import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const getActiveTab = (tabs: TabProps[]) => {

  const index = tabs.findIndex(t => t.url === window.location.pathname);
  
  return index > 0 ? index : 0;
};

const Tabs = ({ tabs }: TabsProps) => {

  const classes = useStyles();
  const [value, setValue] = useState(getActiveTab(tabs));

  
  const handleChange = (event: any, newValue:number) => {
    setValue(newValue);
  };

  return (
    <MuiTabs
      className={classes.root}
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
    >
      {tabs.map((tab) => (
        <Tab label={tab.label} component={Link} to={tab.url} />
      ))}
    </MuiTabs>
  );
};

export interface TabsProps {
  tabs: TabProps[]
}
export interface TabProps {
  label: string,
  url: string
};

export default Tabs;

import React, { useState } from 'react'
import {
    Box,
    Checkbox,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Typography
} from '@mui/material'
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {
    StyledFilterButton,
    classes as styledFilterButtonClasses
} from './CuiFilterButton'
interface CuiFilterProps {
    text: string
    options: any[]
    selectedOptions: any[]
    setSelectedOption: (selectedOptions: any[]) => void
    format?: (option: any) => string
}

export default function CuiFilter({
    text,
    options,
    selectedOptions,
    setSelectedOption,
    format
}: CuiFilterProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget)

    const onClose = () => setAnchorEl(null)

    const getFormattedValue = (value: any) => (format ? format(value) : value)

    const onToggleCheck = (value: any) => () => {
        const currentIndex = selectedOptions.indexOf(value)
        const newSelected = [...selectedOptions]

        if (currentIndex === -1) {
            newSelected.push(value)
        } else {
            newSelected.splice(currentIndex, 1)
        }

        setSelectedOption(newSelected)
    }

    const onAllOptionChange = () =>
        setSelectedOption(selectedOptions.length === options.length ? [] : options.map(o=>getFormattedValue(o)))

    const isFiltered = selectedOptions.length > 0
    return (
        <>
            <StyledFilterButton
                variant={isFiltered ? 'text' : 'outlined'}
                size="small"
                onClick={onClick}
            >
                {isFiltered && (
                    <CloseIcon
                        className={styledFilterButtonClasses.filteredTag}
                        fontSize="small"
                        onClick={event => {
                            setSelectedOption([])
                            event.stopPropagation()
                        }}
                    />
                )}
                {text}
                {selectedOptions.length === 1 && (
                    <Typography
                        variant="body2"
                        className={styledFilterButtonClasses.filteredTag}
                    >
                        {getFormattedValue(selectedOptions[0])}
                    </Typography>
                )}
                {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </StyledFilterButton>
            {anchorEl && (
                <Popover
                    sx={{ maxHeight: 500, padding: 0 }}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 50, horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={onClose}
                >
                    <Box display="flex" justifyContent="space-between" px={1}>
                        <Box
                            fontWeight={500}
                            paddingLeft={2}
                            paddingTop={1}
                            fontSize="20px"
                        >
                            {text}
                        </Box>
                        <IconButton aria-label="delete" onClick={onClose} size="large">
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <List
                        sx={{
                            padding: theme => theme.spacing(2, 2, 0, 2),
                            backgroundColor: theme => theme.palette.background.paper,
                            maxWidth: '260px'
                        }}
                    >
                        <ListItem key={'all'} dense button onClick={onAllOptionChange}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={options.length === selectedOptions.length}
                                    indeterminate={
                                        selectedOptions.length > 0 &&
                                        selectedOptions.length < options.length
                                    }
                                    disableRipple
                                    color="primary"
                                    tabIndex={-1}
                                />
                            </ListItemIcon>
                            <ListItemText primary={'Select All'} />
                        </ListItem>
                        <Divider />
                        {options
                            .sort((a, b) => (a > b ? 1 : -1))
                            .map(opt => {
                                return (
                                    <ListItem
                                        key={opt.key}
                                        dense
                                        button
                                        onClick={onToggleCheck(getFormattedValue(opt))}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selectedOptions.indexOf(getFormattedValue(opt)) !== -1}
                                                disableRipple
                                                color="primary"
                                                tabIndex={-1}
                                                onClick={onToggleCheck(getFormattedValue(opt))}

                                            />
                                        </ListItemIcon>
                                        <ListItemText onClick={onToggleCheck(getFormattedValue(opt))}
                                            key={opt.key} primary={getFormattedValue(opt)} />
                                    </ListItem>
                                )
                            })}
                    </List>
                </Popover>
            )}
        </>
    )
}
import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import config from "../config";
import fetcher from "../utils/fetcher";
import Department from "../entities/Department";

interface Props {
  defaultValue?: number;
  onChange?: (id: number) => void;
  disabled?: boolean;
}

const DepartmentSelect = ({ defaultValue, onChange, disabled }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selected, setSelected] = useState<number|undefined>(defaultValue);

  useEffect(() => {
    const url = `${config.apiUrl}/CodeTable/GetDepartmentList`;
    fetcher
      .get(url)
      .then((response) => response.json())
      .then((data) => setDepartments(data));
  }, []);

  return (
    <FormControl variant="outlined">
      <InputLabel>Department</InputLabel>
      <Select
        value={selected}
        onChange={(e: any) => {
            const value = e.target.value;
            setSelected(value);
            onChange&&onChange(value);
        }}
        label="Department"
        disabled={disabled}
      >
        {departments.map((department: any) => (
          <MenuItem key={department.key} value={department.key}>{department.value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DepartmentSelect;

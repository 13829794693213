import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Event from "../../../entities/Event";
import TestsList from "./tests/TestsList";
import MainContainer from "../../MainContainer";
import Tabs from "../../event/Tabs";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ParticipantsList from "../../shlCycle/participants/ParticipantsList";
import LevelDetails from "./LevelDetails";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tab: {
    textTransform: 'none',
    fontSize: theme.spacing(1.875),
    color: theme.palette.text.secondary,
    minWidth: 'fit-content',
    margin: theme.spacing(1),
    padding: 0,

  },
  root: {
    height: theme.spacing(4),
    minHeight: theme.spacing(4)
  },
  selected: {
    backgroundColor: '#d6eaff',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  label: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5)
  }
}));

export default function LevelPage() {
  const [event] = useState<Event | null>(null);
  const { levelId } = useParams<{ levelId: string }>();
  let { path, url } = useRouteMatch();

  const classes = useStyles();

  const links = [
    {
      label: "Participants",
      url: `${url}/shlCycleParticipant`,
      className: classes.tab,
      classes: {
        root: classes.root,
        selected: classes.selected,
        wrapper: classes.label
      }
    },
    {
      label: "Tests",
      url: `${url}/TestsList`,
      className: classes.tab,
      classes: {
        root: classes.root,
        selected: classes.selected,
        wrapper: classes.label
      }
    },
  ];

  return (
    <>
      <Grid container spacing={1} xs={12} alignItems="stretch">
        <Grid item xs={3}>
          <LevelDetails levelId={levelId} />
        </Grid>
        <Grid item xs={9} >
          <Grid item xs={12} >
            <MainContainer title={event?.eventName}>
              <Tabs tabs={links}
                aria-label="filter-tabs"
              ></Tabs>
              <Switch>
                <Route path={`${path}/TestsList`}>
                  <TestsList levelId={levelId}></TestsList>
                </Route>
                <Route exact path={`${path}/shlCycleParticipant`}>
                  <ParticipantsList
                    levelId={levelId} ></ParticipantsList>
                </Route>
                <Redirect from={`${path}`} to={`${path}/shlCycleParticipant`} />
              </Switch>
            </MainContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

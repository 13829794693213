import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {Button, Stack} from '@mui/material'
import {GridFilterModel, GridFilterItem} from '@mui/x-data-grid-pro'
import config from "../../../../config";
import {useAuth} from '../../../../context/Auth'
import CuiFilter from '../../../CuiFilter'
import {GridColumn} from './TestsTable'
import fetcher from '../../../../utils/fetcher';
import ExaminationType from '../../../../entities/ExaminationType';

export interface AdminIssueFilterProps {
  filterModel: GridFilterModel
  setFilterModel: Dispatch<SetStateAction<GridFilterModel>>
}

const getFilterItem = (
  items: GridFilterItem[],
  columnField: string,
  operatorValue: string = 'isAnyOf'
) => {
  return items.find(
    c => c.columnField === columnField && c.operatorValue === operatorValue
  )
}

const changeFilterData = (
  items: GridFilterItem[],
  columnField: string,
  value: any,
  operatorValue: string = 'isAnyOf'
) => {
  const current = getFilterItem(items, columnField, operatorValue)
  if (!value || value.length < 1) {
    return items.filter(item => item !== current)
  }
  if (current) {
    return items.map(item =>
      item === current ? {...item, value: value} : item
    )
  }
  const newFilter = {
    columnField,
    operatorValue,
    value,
    id: items.length + 1
  }
  return [...items, newFilter]
}

export default function AdminIssueFilter({
  filterModel,
  setFilterModel,
}: AdminIssueFilterProps) {
  const [ExaminationType, setExaminationType] = useState<ExaminationType[]>([])

  const {fetchWithUser} = useAuth()

  const getFilterModel = (
    columnField: string,
    operatorValue: string = 'isAnyOf'
  ) => getFilterItem(filterModel.items, columnField, operatorValue)

  const onChangeFilterModel = useCallback(
    (columnField: string, value: any, operatorValue: string = 'isAnyOf') => {
      setFilterModel(p => ({   
        ...p,
        items: changeFilterData(p.items, columnField, value, operatorValue)
      }))
    },
    [setFilterModel]
  )

  useEffect(() => {
      fetcher
      .get(`${config.apiUrl}/CodeTable/GetExaminationTypeList`)
      .then((response) => response.json())
      .then((data) => {
          setExaminationType(data);
      });
  }, [fetchWithUser])

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={0}
    >
      <Button
        //color="neutral"
        onClick={() => setFilterModel(p => ({...p, items: []}))}
      >
        Clear All
      </Button>
      <CuiFilter
        text="Levels"
        options={ExaminationType}
        selectedOptions={getFilterModel(GridColumn.Level)?.value || []}
        setSelectedOption={selected =>
          onChangeFilterModel(GridColumn.Level, selected)
        }
        format={(o: any) => o.value}
      />
      
    </Stack>
  )
}
import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Modal from "../../../Modal";
import fetcher from "../../../../utils/fetcher";
import config from "../../../../config";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExaminationCycle from "../../../../entities/ExaminationCycle";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { isValid } from "date-fns";
import converStringDateToUtcDate from '../../../ConvertDate'

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            "& > *": {
                margin: spacing(1),
                width: `calc(50% - ${spacing(2)}px)`,
            },
        },
        fullWidth: {
            width: `calc(100% - ${spacing(2)}px)`,
        },
    })
);

const isEdit = (examination: ExaminationCycle) => {
    return examination && examination.examinationID;
};

const getUrl = (examination: ExaminationCycle) => {
    if (isEdit(examination)) return `${config.apiUrl}/Examination/UpdateExamination`;
    return `${config.apiUrl}/Examination/AddExamination`;
};

const TestEditor = ({ examinationName, examination, onSave, ...props }: any) => {
    const [data, setData] = useState<any>(examination);

    const classes = useStyles();

    const setValue = (key: string, value: any) => {
        setData({
            ...data,
            [key]: value,
        });
    };

    useEffect(() => {
        const { ...fields } = examination;
        setData(fields);
    }, [examination]);

    const onSubmit = () => {
        data.submissionTime = converStringDateToUtcDate(data.submissionTime)
        data.reminder1 = converStringDateToUtcDate(data.reminder1)
        data.reminder2 = converStringDateToUtcDate(data.reminder2)
        // data.examinationName?data.examination.examinationName=data.examinationName
        // :data.examination.examinationName=data.examination.examinationName
        const url = getUrl(data);
        fetcher
            .post(url, {
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(() => onSave(data));
    };

    return (
        <Modal
            maxWidth="sm"
            title={isEdit(examination) ? "Edit Examination" : "Add Examination"}
            onSave={onSubmit}
            {...props}
        >
            <Grid container>
                <Grid>
                    <form className={classes.root}>
                        <TextField
                            name="examinationName"
                            variant="outlined"
                            label="Examination Name"
                            defaultValue={data.examinatiomName}
                            onChange={(e) => setValue("examinationName", e.target.value)}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                fullWidth={true}
                                value={data.submissionTime}
                                defaultValue={data.submissionTime}
                                onChange={(date) => {
                                    if (date && isValid(date)) {
                                        setValue("submissionTime", date);
                                    }
                                }}
                                format="dd-MM-yyyy  HH:mm:ss"
                                variant="inline"
                                inputVariant="outlined"
                            ></DateTimePicker>
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                fullWidth={true}
                                value={data.reminder1}
                                defaultValue={data.reminder1}
                                onChange={(date) => {
                                    if (date && isValid(date)) {
                                        setValue("reminder1", date)
                                    }
                                }}
                                format="dd-MM-yyyy HH:mm:ss"
                                variant="inline"
                                inputVariant="outlined"
                            ></DateTimePicker>
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                fullWidth={true}
                                value={data.reminder2}
                                defaultValue={data.reminder2}
                                onChange={(date) => {
                                    if (date && isValid(date)) {
                                        setValue("reminder2", date);
                                    }
                                }}
                                format="dd-MM-yyyy HH:mm:ss"
                                variant="inline"
                                inputVariant="outlined"
                            ></DateTimePicker>
                        </MuiPickersUtilsProvider>
                    </form>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default TestEditor;
import React from 'react'
import {styled} from '@mui/material/styles'
import {Button, Typography} from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
const PREFIX = 'CuiFilterButton'

export const classes = {
  outlinedFilterBtn: `${PREFIX}-outlinedFilterBtn`,
  filteredBtn: `${PREFIX}-filteredBtn`,
  filteredTag: `${PREFIX}-filteredTag`
}

export const StyledFilterButton = styled(Button)(({theme}) => ({
  [`&.${classes.outlinedFilterBtn}`]: {
    borderRadius: '28px',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  [`&.${classes.filteredBtn}`]: {
    backgroundColor: 'rgb(130 , 177 ,255 , 0.2);'
  },
  [`& .${classes.filteredTag}`]: {
    marginLeft: theme.spacing(0.5)
  }
}))
interface CuiFilterButtonProps {
  value: any
  clearValue: () => void
  isPopuop: boolean
  setAnchorEl: (anchorEl: null | HTMLButtonElement) => void
  text: string
}

export default function CuiFilterButton({
  value,
  clearValue,
  isPopuop,
  setAnchorEl,
  text
}: CuiFilterButtonProps) {
  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  return (
    <StyledFilterButton
      variant={value ? 'text' : 'outlined'}
      size="small"
      onClick={onOpen}
    >
      {value && (
        <CloseIcon
          className={classes.filteredTag}
          fontSize="small"
          onClick={event => {
            clearValue()
            event.stopPropagation()
          }}
        />
      )}
      <Typography variant="body2" className={classes.filteredTag}>
        {text}
      </Typography>
      {isPopuop ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </StyledFilterButton>
  )
}
import React from "react";
import ExaminationType from "../../../../entities/ExaminationType";
import { Box, makeStyles } from "@material-ui/core";
import TestActions from "./TestActions";
import { DataGridPro, GridToolbarExport, useGridApiRef } from "@mui/x-data-grid-pro";



const useStyles = makeStyles((theme) =>
({
    root: {
        fontSize: "12px",
        boxShadow: "0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03)",
        border: "none",
        borderRadius: "4px",
        marginTop: theme.spacing(5)
    },
    toolbar: {
        display: "none",
    },
})
);
export enum GridColumn {
    ExaminationName = 'examinationName',
    Status = 'status',
    SubmissionTime = 'submissionTime',
    Reminder1 = 'reminder1',
    Reminder2 = 'reminder2',
    Level = 'level',
    Actions = 'actions',
}

const generateColumns = (props: any, examinationType: ExaminationType[]) => [
    // { field: "examinationID", headerName: "ID", hide: true },
    {
        field: GridColumn.ExaminationName,
        headerName: "Examination Name",
        width: 200,
        renderCell: (params: any) => {
            return params.row.examination.examinationName
        },
    },
    {
        field: GridColumn.Status,
        headerName: "Status",
        width: 150,
        renderCell: (params: any) => {
            return params.row.examination.status
        },
    },
    {
        field: GridColumn.SubmissionTime,
        headerName: "Submission Time",
        width: 200,
    },
    {
        field: GridColumn.Reminder1,
        headerName: "Reminder 1",
        width: 200,
    },
    {
        field: GridColumn.Reminder2,
        headerName: "Reminder 2",
        width: 200,
    },
    {
        field: GridColumn.Actions,
        headerName: "Actions",
        sortable: false,
        disableExport: true,

        width: 140,
        renderCell: (params: any) => {
            return <TestActions examination={params.row} {...props}></TestActions>;
        },
    },
    {
        field: GridColumn.Level,
        headerName: "Level",
        width: 200,
        disableExport: false,

        renderCell: (params: any) => {
            return params.row.examination.examinationTypeName
        },
    },
    // {
    //     field: GridColumn.Actions,
    //     headerName: "Actions",
    //     sortable: false,
    //     width: 140,
    //     renderCell: (params: any) => {
    //         return <TestActions examination={params.row} {...props}></TestActions>;
    //     },
    // },
];

const getRowId = (row: any) => row.examinationCycleID;

export default function ExaminationsTable(props: any) {
    const { rows, examinations } = props;
    const apiRef = useGridApiRef()
    const classes = useStyles();

    return (
        <>
            <Box sx={{ mt: 2, width: '100%', height: "100vh" }}>
                <DataGridPro
                 //   disableColumnMenu
                    className={classes.root}
                    rows={rows}
                    columns={generateColumns(props, examinations)}
                    filterModel={props.filterModel}
                     apiRef={apiRef}
                    pagination
                    pageSize={5}
                    getRowId={getRowId}
                    components={{
                        Toolbar: () => <GridToolbarExport  />
                    }}
                    /*//  disableColumnMenu
                className={classes.root}
                rows={rows}
                columns={generateColumns(props, departments)}
                filterModel={props.filterModel}
                apiRef={apiRef}
                // columnVisibilityModel={{
                //     [GridColumn.Actions]: false,
                // }}
                pagination
                pageSize={5}
                getRowId={getRowId}
                components={{
                    Toolbar: () => <GridToolbarExport printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }} />
                }}
 */

                />
            </Box>
        </>
    );
}


import React from 'react'
import {Button, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useAuth} from '../../context/Auth'
import MicrosoftIcon from '../../icons/MicrosoftIcon'

const useStyles = makeStyles(theme => ({
  submit: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(3)
  },
  whiteColor: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  }
}))

interface LoginButtonProps {
  color?: 'white'
}

export default function LoginButton({color}: LoginButtonProps) {
  const classes = useStyles()
  const {login, isLoggingIn} = useAuth()

  return (
    <Button
      type="submit"
      variant="outlined"
      onClick={() => login()}
      startIcon={<MicrosoftIcon />}
      endIcon={
        isLoggingIn && (
          <CircularProgress
            size={24}
            className={color ? classes.whiteColor : ''}
          />
        )
      }
    >
      Continue with Microsoft
    </Button>
  )
}
